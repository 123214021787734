import {
  THomeEventFilterGroup,
  THomeEventType,
} from "src/data/events/types/homeEventTypes"
import { langKeys, LangType } from "src/i18n/langKeys"
import { TSelectOption } from "src/ui/DropdownSelect/DropdownSelect"
import { debug } from "src/utils/logger"

/**
 * This object maps all available home log event types to a filter group.
 *
 * While the reverse mapping is typically more useful in practice, we start with
 * this mapping as a source of truth in order to ensure that all available event
 * filters are accounted for. I.e., if the BE adds a new event type, we need to
 * add it here or the app won't compile.
 *
 * Some event types are purposefully assigned `null` values either because they
 * are not relevant any longer or because the user should not be able to filter
 * based on them; in such cases a comment will explain why.
 */
export const homeEventToFilterGroupMap: {
  [K in THomeEventType]: null | THomeEventFilterGroup[]
} = {
  alarm_grace_period_expired: ["security_alarm"],
  alarm_heard: ["alarm_recognition"],
  alarm_muted_button_press: ["security_alarm"],
  alarm_silenced: ["security_alarm"],
  almost_freezing: ["temperature"],
  avg_sound_high: null, // P1 only, let's ignore
  battery_empty: ["battery"],
  battery_low: ["battery"],
  call_assist_call_completed: ["call_assist"],
  call_assist_call_started: ["call_assist"],
  co_alarm: ["fire_and_co"],
  co_cleared: ["fire_and_co"],
  crowd_detected: ["crowd_detect"],
  device_install: ["sensor_installed"],
  device_offline: ["sensor_offline"],
  device_online: ["sensor_online"],
  dispatch_cancelled_by_minut_while_in_progress: ["guard_assist"],
  dispatch_cancelled_by_minut_while_requested: ["guard_assist"],
  dispatch_cancelled_by_provider: ["guard_assist"],
  dispatch_cancelled_by_user: ["guard_assist"],
  dispatch_requested_automatically: ["guard_assist"],
  dispatch_requested_manually: ["guard_assist"],
  fire_and_co_end_of_life: ["fire_and_co"],
  fire_and_co_self_test_fail: ["fire_and_co"],
  fire_and_co_self_test_success: ["fire_and_co"],
  fire_smoke_alarm: ["fire_and_co"],
  fire_smoke_cleared: ["fire_and_co"],
  humidity_dropped_normal: ["humidity"],
  humidity_high: ["humidity"],
  humidity_low: ["humidity"],
  humidity_risen_normal: ["humidity"],
  noise_detected: ["noise"],
  noise_quieted: ["noise"],
  response_service_dispatch_arrived: ["guard_assist"],
  response_service_dispatch_completed: ["guard_assist"],
  response_service_dispatch_in_progress: ["guard_assist"],
  risk_of_mould: ["mold_risk"],
  risk_of_mould_cleared: ["mold_risk"],
  sensor_swap_failed: ["sensor_swap"],
  sensor_swap_succeeded: ["sensor_swap"],
  short_button_press: ["button_pressed"],
  smoking_calibration_done: ["smoking_detection"],
  smoking_detected: ["smoking_detection"],
  sound_level_dropped_normal: null, // P1 only, let's ignore
  tamper: null, // P1 only, let's ignore
  tamper_mounted: ["sensor_attached"],
  tamper_removed: ["sensor_removed"],
  temperature_dropped_normal: ["temperature"],
  temperature_high: ["temperature"],
  temperature_low: ["temperature"],
  temperature_risen_normal: ["temperature"],
} as const

/**
 * This function maps all filter groups to a list of home log events based on
 * the information in `homeEventFilterGroupMap`.
 *
 * Event types assigned a `null` value will be excluded until and if they get
 * assigned a specific filter group.
 */
function createFilterGroupToHomeEventMap(): {
  [K in THomeEventFilterGroup]: THomeEventType[]
} {
  const reverseMap = {} as { [K in THomeEventFilterGroup]: THomeEventType[] }
  function addGroup(g: THomeEventFilterGroup, eventType: THomeEventType) {
    if (reverseMap[g]) {
      reverseMap[g].push(eventType)
    } else {
      reverseMap[g] = [eventType]
    }
  }

  for (const [_eventType, filterGroup] of Object.entries(
    homeEventToFilterGroupMap
  )) {
    if (!filterGroup) {
      continue
    }

    const eventType = _eventType as THomeEventType
    for (const g of filterGroup) {
      addGroup(g, eventType)
    }
  }
  return reverseMap
}

export const filterGroupToHomeEventType = createFilterGroupToHomeEventMap()

const homeEventFilterGroupTranslations: {
  [K in THomeEventFilterGroup]: keyof LangType | null
} = {
  // Specific groups
  alarm_recognition: langKeys.event_filter_alarm_recognition_label,
  button_pressed: langKeys.event_filter_button_pressed_label,
  crowd_detect: langKeys.event_filter_crowd_detect_label,
  mold_risk: langKeys.event_filter_mold_risk_label,
  security_alarm: langKeys.event_filter_security_alarm_label,
  sensor_attached: langKeys.event_filter_sensor_attached_label,
  sensor_installed: langKeys.event_filter_sensor_installed_label,
  sensor_offline: langKeys.event_filter_sensor_offline_label,
  sensor_online: langKeys.event_filter_sensor_online_label,
  sensor_removed: langKeys.event_filter_sensor_removed_label,
  sensor_swap: langKeys.event_filter_sensor_swap_label,

  // General groups
  battery: langKeys.battery,
  call_assist: langKeys.call_assist,
  fire_and_co: langKeys.fire_and_co_alarm_title,
  guard_assist: langKeys.minut_response_service,
  humidity: langKeys.humidity,
  noise: langKeys.device_settings_noise,
  smoking_detection: langKeys.smoking_detection_smoking_detected_title,
  temperature: langKeys.temperature,
} as const

export const homeEventFilterOptions: TSelectOption<THomeEventFilterGroup>[] =
  Object.entries(homeEventFilterGroupTranslations)
    .filter(([filterGroup, _]) => {
      const hasFilterGroup =
        !!filterGroupToHomeEventType[filterGroup as THomeEventFilterGroup]
      if (!hasFilterGroup) {
        debug.warn("No event types assigned to filter group:", filterGroup)
      }
      return hasFilterGroup
    })
    .map(([key, langKey]) => {
      return {
        value: key as THomeEventFilterGroup, // casting because Object.entries loses typing
        label: langKey ?? key,
      }
    })
